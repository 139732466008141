import React from 'react';

import { t } from '@/utility/localization';
import withComponentClassName from '@/utility/withComponentClassName';

import InfiniteList from '@/components/common/InfiniteList';
import Popover from '@/components/common/Popover';

import SearchBox from '@/pages/portal/components/SearchBox/SearchBox';

import Icon from '../Icon';
import type {
  NPLSearchDropdownItemProps,
  NPLSearchDropdownProps
} from './types';

const NPLSearchDropdown: React.FC<NPLSearchDropdownProps> = ({
  searchPlaceholder,
  hasSearch,
  onSearch,
  onChange,
  searchValue,
  isMultiSelect,
  children,
  options,
  loading,
  fetchMore,
  emptyMessage,
  emptyIcon,
  hasMore
}) => {
  // TODO-neil
  // multiselect footer with save and select all

  return (
    <Popover
      placement="bottom-start"
      content={(onClose) => (
        <div>
          <div className="flex flex-col w-full relative shadow-lg min-w-[300px] max-w-[400px] max-h-[400px] overflow-y-auto overflow-hidden rounded-8 bg-npl-base-white px-8 py-12 min-h-0">
            {hasSearch && (
              <div className="w-full px-8 pt-4 pb-8">
                <SearchBox
                  loading={loading}
                  placeholder={searchPlaceholder}
                  initialValue={searchValue}
                  onChange={onSearch}
                />
              </div>
            )}
            {options.length === 0 && (
              <div className="w-full px-8 py-12 text-label-md font-medium text-npl-text-secondary-on-light flex flex-col items-center">
                <div className="rounded-full h-48 w-48 p-10 bg-npl-neutral-light-alpha-3 mb-12 mt-12">
                  {
                    <Icon
                      name={emptyIcon || 'box'}
                      fill="#1B1B1899"
                      height={28}
                      width={28}
                    />
                  }
                </div>
                <p>{emptyMessage || t('no-items-found')}</p>
              </div>
            )}
            <div className="flex-1 overflow-auto">
              <InfiniteList
                items={options}
                isLoading={loading}
                isChunked={false}
                hasMore={hasMore}
                loadMore={() => {
                  fetchMore && fetchMore();
                }}
                renderSkeleton={() => (
                  <div className="h-[56px] w-full rounded-4 bg-npl-neutral-light-alpha-3" />
                )}>
                {(option, index) => {
                  const {
                    lead,
                    tail,
                    label,
                    value,
                    eyebrow,
                    bottomDivider
                  } = option as NPLSearchDropdownItemProps;
                  return (
                    <>
                      <div
                        key={index}
                        onClick={() => {
                          onChange(value);
                          !isMultiSelect && onClose();
                        }}
                        className="w-full px-8 rounded-4 h-[56px] flex flex-row items-center justify-between hover:bg-npl-neutral-alpha-2 cursor-pointer">
                        {lead && (
                          <div className="pr-8 py-12 text-label-lg text-npl-text-secondary-on-light ">
                            {lead}
                          </div>
                        )}
                        <div className="flex flex-grow flex-col items-stretch w-full truncate">
                          {eyebrow && (
                            <span className="text-label-sm text-npl-text-secondary-on-light mb-2">
                              {eyebrow}
                            </span>
                          )}
                          <span className="text-label-lg text-npl-text-primary-on-light overflow-ellipsis whitespace-nowrap truncate">
                            {label}
                          </span>
                        </div>
                        {tail && (
                          <div className="pl-8 py-12 text-body-xs text-npl-text-secondary-on-light">
                            {tail}
                          </div>
                        )}
                      </div>
                      {bottomDivider && (
                        <div className="w-full min-h-[1px] bg-npl-neutral-light-solid-4 my-4" />
                      )}
                    </>
                  );
                }}
              </InfiniteList>
            </div>
          </div>
        </div>
      )}>
      <button>{children}</button>
    </Popover>
  );
};

export default withComponentClassName(NPLSearchDropdown, false);
